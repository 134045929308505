<template>
  <div>
    <a-card :title="$t('货品批次')">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-input v-model="searchForm.search" :placeholder="$t('批次编号')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px;">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-select v-model="searchForm.material" :placeholder="$t('货品')" allowClear style="width: 100%;" @change="search">
            <a-select-option v-for="item in materialItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row>
        <a-table size="small" :columns="columns" :dataSource="items" :loading="loading" :pagination="pagination"
          @change="tableChange">
          <div slot="production_date" slot-scope="value">{{value ? moment(value).format('YYYY-MM-DD') : ''}}</div>
          <div slot="expiration_date" slot-scope="value">{{value ? moment(value).format('YYYY-MM-DD') : ''}}</div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
  import { batchList } from '@/api/material';
  import { materialOption } from '@/api/option';
  import columns from './columns.js'
  import moment from 'moment'

  export default {
    data() {
      return {
        moment,
        columns,
        searchForm: { search: '', page: 1, ordering: undefined, material: undefined },
        pagination: { current: 1, total: 0, pageSize: 16 },
        loading: false,
        items: [],

        materialItems: [],
      };
    },
    computed: {
      currentWarehouse() {
        return this.$store.state.user.currentWarehouse
      },
    },
    methods: {
      initialize() {
        this.searchForm.warehouse = this.currentWarehouse;
        this.list();

        materialOption({ page_size: 999999 }).then(data => {
          this.materialItems = data.results;
        });
      },
      list() {
        this.loading = true;
        batchList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      create(item) {
        this.items.splice(0, 0, item);
      },
      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>

<style scoped>
</style>