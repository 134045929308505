import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("批次编号"),
    dataIndex: "number",
    sorter: true,
  },
  {
    title: T("创建时间"),
    dataIndex: "create_time",
    sorter: true,
  },
  {
    title: T("货品名称"),
    dataIndex: "material_name",
    ellipsis: true,
  },
  {
    title: T("货品编号"),
    dataIndex: "material_number",
    sorter: true,
  },
  {
    title: T("入库数量"),
    dataIndex: "total_quantity",
  },
  {
    title: T("剩余数量"),
    dataIndex: "remain_quantity",
  },
  {
    title: T("入库数量"),
    dataIndex: "production_date",
    scopedSlots: { customRender: "production_date" },
    sorter: true,
  },
  {
    title: T("过期日期"),
    dataIndex: "expiration_date",
    scopedSlots: { customRender: "expiration_date" },
    sorter: true,
  },
];
